import { takeLatest, call, put } from 'redux-saga/effects';

import {
    LOAD_RANKS, LOAD_TECHNIQUE_TYPES,
    LOAD_DEFENSES, LOAD_REP_TYPES, LOAD_WORKOUTS,
    loadedRanks, loadedTechniqueTypes,
    loadedDefenses, loadedRepTypes, loadedWorkouts
} from './actions'
import { 
    fetchRanks, fetchDefenses, fetchTechniqueTypes, fetchRepTypes, fetchWorkouts
} from '../graphClient';

function* loadRanks(action) {
    try {
        const ranks = yield call(fetchRanks);
        yield put(loadedRanks(ranks));
    } catch (error) {
        // yield put(crashReportLoadError(error));
    }
};

function* loadTechniqueTypes(action) {
    try {
        const techniqueTypes = yield call(fetchTechniqueTypes);
        yield put(loadedTechniqueTypes(techniqueTypes));
    } catch (error) {
        // yield put(crashReportLoadError(error));
    }
};

function* loadDefenses(action) {
    try {
        const defenses = yield call(fetchDefenses);
        yield put(loadedDefenses(defenses));
    } catch (error) {
        // yield put(crashReportLoadError(error));
    }
};

function* loadRepTypes(action) {
    try {
        const repTypes = yield call(fetchRepTypes);
        yield put(loadedRepTypes(repTypes));
    } catch (error) {
        // yield put(crashReportLoadError(error));
    }
};

function* loadWorkouts(action) {
    try {
        const workouts = yield call(fetchWorkouts);
        yield put(loadedWorkouts(workouts));
    } catch (error) {
        // yield put(crashReportLoadError(error));
    }
};

function* app() {
    yield takeLatest(LOAD_RANKS, loadRanks);
    yield takeLatest(LOAD_TECHNIQUE_TYPES, loadTechniqueTypes);
    yield takeLatest(LOAD_DEFENSES, loadDefenses);
    yield takeLatest(LOAD_REP_TYPES, loadRepTypes);
    yield takeLatest(LOAD_WORKOUTS, loadWorkouts);
};

export default app;