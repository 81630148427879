import React, { Component } from 'react';
import { browserHistory } from 'react-router';

import { exportTechniqueUrl } from '../api';

class ExportTechnique extends Component {
    componentDidMount() {
        location.href = exportTechniqueUrl();
    }
    render() {
        return (
            <div className="starter-template">
                <h1>Technique Export</h1>
                <p>Complete! If download does not start automatically, click <a href={exportTechniqueUrl()}>here</a>.</p>
            </div>
        );
    }
};

export default ExportTechnique;