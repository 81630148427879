import React, { Component } from 'react';

import './techniqueDrillSetup.css';

class DrillSetup extends Component {
    componentWillMount() {
        this.props.load();
    }
    render() {
        const {
            isLoading, ranks, techniqueTypes, selectedRanks, selectedTechniqueTypeId,
            secondsBetweenTechniques, numberOfTechniques, maxNumberOfTechniques,
            toggleRank, changeTechniqueType, changeSecondsBetweenTechniques, changeNumberOfTechniques,
            startDrill
        } = this.props;

        if (isLoading) {
            return <div>Loading...</div>;
        }

        return (
            <div className="drill-setup-container">
                <div className="row">
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label>Ranks</label>
                            <div className="rank-picker">
                                {ranks.map((rank, index) =>
                                    <div key={index} className="checkbox">
                                        <label>
                                            <input type="checkbox"
                                                checked={selectedRanks.has(rank.id)}
                                                onChange={() => toggleRank(rank.id)} />
                                            {rank.name}
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label>Technique Type</label>
                            <select className="form-control"
                                value={selectedTechniqueTypeId}
                                onChange={e => changeTechniqueType(e.target.value)}>
                                <option></option>
                                {techniqueTypes.map((techniqueType, index) =>
                                    <option key={index} value={techniqueType.id}>
                                        {techniqueType.name}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label>Seconds between techniques</label>
                            <input className="form-control"
                                value={secondsBetweenTechniques}
                                type="number" step="1" min="1" max="10"
                                onChange={e => changeSecondsBetweenTechniques(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label>Number of techniques</label>
                            <input className="form-control"
                                value={numberOfTechniques}
                                type="number" step="1" min="1" max={maxNumberOfTechniques}
                                onChange={e => changeNumberOfTechniques(e.target.value)}
                                disabled={!selectedTechniqueTypeId || !selectedRanks.size} />
                        </div>
                    </div>
                </div>
                <div className="row start-button">
                    <div className="col-xs-12 col-md-4 col-md-offset-4">
                        <button
                            className="btn btn-success btn-lg btn-block"
                            onClick={() =>
                                startDrill(selectedTechniqueTypeId, selectedRanks, numberOfTechniques, secondsBetweenTechniques)
                            }>
                            Start
                    </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default DrillSetup;
