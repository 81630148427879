import { LOADED_TECHNIQUE_NAMES } from '../../technique/picker/actions';
import { ADD_TECHNIQUE_TO_WORKOUT, REORDER_WORKOUT_TECHNIQUES, REMOVE_TECHNIQUE_FROM_WORKOUT, SAVED_WORKOUT } from './actions';

const reorderTechniques = (techniques, oldIndex, movedUp) => {
    let newIndex = movedUp ? oldIndex - 1 : oldIndex + 1;

    if (newIndex < 0 || newIndex >= techniques.length) {
        newIndex = oldIndex;
    }

    techniques.splice(newIndex, 0, techniques.splice(oldIndex, 1)[0]);
    return techniques;
};

const newWorkout = (
    state = {
        allTechniques: [],
        techniques: []
    }, action) => {
    switch (action.type) {
        case LOADED_TECHNIQUE_NAMES:
            return {
                ...state,
                allTechniques: action.techniqueNames
            };
        case REORDER_WORKOUT_TECHNIQUES:
            return {
                ...state,
                techniques: reorderTechniques([...state.techniques], action.index, action.movedUp)
            };
        case ADD_TECHNIQUE_TO_WORKOUT:
            return {
                ...state,
                techniques: [
                    ...state.techniques,
                    action.technique
                ]
            };
        case REMOVE_TECHNIQUE_FROM_WORKOUT:
            const techniques = [...state.techniques]
            techniques.splice(action.index, 1);

            return {
                ...state,
                techniques
            };
        case SAVED_WORKOUT:
            return {
                ...state,
                workoutId: action.workout.id
            };
        default:
            return state;
    }
};

export default newWorkout;
