export const START_DRILL = 'START_DRILL';
export const RUN_TECHNIQUE_DRILL = 'SAY_PHRASE';
export const DRILL_RUNNING_TECHNIQUE = 'DRILL_RUNNING_TECHNIQUE';
export const DRILL_SECOND_ELAPSED = 'DRILL_SECOND_ELAPSED';
export const DRILL_COMPLETE = 'DRILL_COMPLETE';

export const startDrill = (techniqueTypeId, rankIds, numberOfTechniques, secondsBetweenTechniques) => {
    return {
        type: START_DRILL,
        startParameters: {
            techniqueTypeId,
            rankIds: [...rankIds],
            numberOfTechniques
        },
        secondsBetweenTechniques
    };
};

export const runTechniqueDrill = (techniques, secondsBetweenTechniques) => {
    return {
        type: RUN_TECHNIQUE_DRILL,
        techniques,
        secondsBetweenTechniques
    };
};

export const drillRunningTechnique = techniqueIndex => {
    return {
        type: DRILL_RUNNING_TECHNIQUE,
        techniqueIndex
    };
};

export const drillSecondElapsed = () => {
    return {
        type: DRILL_SECOND_ELAPSED
    };
};

export const drillComplete = () => {
    return {
        type: DRILL_COMPLETE
    };
};
