import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form'

import app from './app/reducer';
import home from './home/reducer';
import newTechnique from './technique/new/reducer';
import editTechnique from './technique/edit/reducer';
import newWorkout from './workout/new/reducer';
import manageWorkouts from './workout/manage/reducer';
import techniqueDrill from './drillInstructor/techniqueDrill/reducer';

const rootReducer = combineReducers({
    app,
    home,
    newTechnique,
    editTechnique,
    newWorkout,
    manageWorkouts,
    form,
    techniqueDrill
});

export default rootReducer;
