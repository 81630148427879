export const REMOVE_WORKOUT = 'REMOVE_WORKOUT';
export const REMOVED_WORKOUT = 'REMOVED_WORKOUT';

export const removeWorkout = workoutId => {
    return {
        type: REMOVE_WORKOUT,
        workoutId
    };
};

export const removedWorkout = () => {
    return {
        type: REMOVED_WORKOUT
    };
};
