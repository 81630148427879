/* global fetch */
/* global localStorage */
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    createHttpLink,
    gql
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
    uri: 'https://us-east-1.aws.realm.mongodb.com/api/client/v2.0/app/kungfutrainer-ychab/graphql',
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('graphToken');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            //   email: 'turnerd18+kfapp@gmail.com', password: 'qw12QW!@',
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});


export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    fetchOptions: {
        mode: 'no-cors',
    },
});


export const fetchTechniqueTypes = async () => {
    try {
        const result = await client
            .query({
                query: gql `
                    query {
                        technique_types {
                            name
                            source_id
                            position
                        }
                    }
            `
            });

        return result.data.technique_types.map(t => ({
            name: t.name,
            id: t.source_id
        }));
    } catch (e) {
        console.error(e);
        localStorage.setItem('graphToken', '');
    }
};

export const fetchRanks = async () => {
    const result = await client
        .query({
            query: gql `
                query {
                    ranks {
                        name
                        source_id
                        position
                    }
                }
        `
        });
        
    const ranks = result.data.ranks.map(r => ({
        name: r.name,
        id: r.source_id,
        position: r.position
    }))
    ranks.sort((r1, r2) => r1.position - r2.position);
    return ranks;
};

export const fetchRepTypes = async () => {
    const result = await client
        .query({
            query: gql `
                query {
                    rep_types {
                        name
                        source_id
                    }
                }
        `
        });
    
    return result.data.rep_types.map(d => ({
        name: d.name,
        id: d.source_id
    }));
};

export const fetchDefenses = async () => {
    const result = await client
        .query({
            query: gql `
                query {
                    defenses {
                        name
                        source_id
                    }
                }
        `
        });
        
    return result.data.defenses.map(d => ({
        name: d.name,
        id: d.source_id
    }));
};

export const fetchWorkouts = async () => {
    const result = await client
        .query({
            query: gql `
                query {
                    workout_configs {
                        name
                        source_id
                    }
                }
        `
        })
        
    return result.data.workout_configs.map(wc => ({
        name: wc.name,
        id: wc.source_id
    }));
};
