import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NavbarLink = ({ className, to, children }) => {
    return (
        <Link to={to} className={className}
            onClick={() => window.$('.navbar-collapse').collapse('hide')}>
            {children}
        </Link>
    );
};

let Navbar = ({ techniqueTypes, ranks, workouts }) => {
    return (
        <div className="navbar navbar-inverse" role="navigation">
            <div className="container">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <NavbarLink className="navbar-brand" to="/">Kung Fu Trainer</NavbarLink>
                </div>
                <div className="collapse navbar-collapse">
                    <ul className="nav navbar-nav">
                        <li className="dropdown">
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">Techniques <b className="caret"></b></a>
                            <ul className="dropdown-menu">
                                {techniqueTypes.map((techniqueType, index) =>
                                    <li key={index}>
                                        <NavbarLink to={`/technique/type/${techniqueType.id}`}>{techniqueType.name}</NavbarLink>
                                    </li>
                                )}
                                <li className="divider"></li>
                                <li><NavbarLink to="/technique/new">New</NavbarLink></li>
                                <li className="divider"></li>
                                <li><NavbarLink to="/technique/export">Export</NavbarLink></li>
                            </ul>
                        </li>
                        <li className="dropdown">
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">Ranks <b className="caret"></b></a>
                            <ul className="dropdown-menu">
                                {ranks.map((rank, index) =>
                                    <li key={index}>
                                        <NavbarLink to={`/technique/rank/${rank.id}`}>{rank.name}</NavbarLink>
                                    </li>
                                )}
                            </ul>
                        </li>
                        <li className="dropdown">
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">Workout <b className="caret"></b></a>
                            <ul className="dropdown-menu">
                                {workouts.map((workout, index) =>
                                    <li key={index}>
                                        <NavbarLink to={`/workout/${workout.id}`}>{workout.name}</NavbarLink>
                                    </li>
                                )}
                                <li className="divider"></li>
                                <li><NavbarLink to="/workout/manage">Manage</NavbarLink></li>
                                <li><NavbarLink to="/workout/new">New</NavbarLink></li>
                            </ul>
                        </li>
                        <li><NavbarLink to="/drill">Drill</NavbarLink></li>
                    </ul>
                    <ul className="nav navbar-nav navbar-right">
                        <li className="nav-right"><NavbarLink to="/logout">Log out</NavbarLink></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

Navbar.propTypes = {
    ranks: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    techniqueTypes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    workouts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired
};

export default Navbar;
