import { fork } from 'redux-saga/effects';

import app from './app/saga';
import newTechnique from './technique/new/saga';
import techniquePicker from './technique/picker/saga';
import workout from './workout/new/saga';
import manageWorkouts from './workout/manage/saga';
import techniqueDrillSetup from './drillInstructor/techniqueDrill/setup/saga';
import techniqueDrillRunner from './drillInstructor/techniqueDrill/runner/saga';

function* rootSaga() {
    yield fork(app);
    yield fork(newTechnique);
    yield fork(techniquePicker);
    yield fork(workout);
    yield fork(manageWorkouts);
    yield fork(techniqueDrillSetup);
    yield fork(techniqueDrillRunner);
};

export default rootSaga;
