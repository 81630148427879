import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { gql, useQuery } from '@apollo/client';

import TechniqueForm from '../TechniqueForm';
import { saveTechnique } from '../new/actions';

const EditTechnique = props => {
    const {
        wasSaved
    } = props;
    
    // useEffect(() => {
//             hashHistory.push(`/technique/${technique.id}`);
    // }, [wasSaved]);
    
    
    const { techniqueId } = useParams();
    
    const LOAD_TECHNIQUE = gql`
        query {
            technique(query: {source_id: ${techniqueId}}) {
                name
                steps
                reps
                rank: rank_id {
                    source_id
                }
                type: type_id {
                    source_id
                }
                defense: defense_id {
                    source_id
                }
                rep_type: rep_type_id {
                    source_id
                }
            }
        }
    `;
    
    const { loading, error, data } = useQuery(LOAD_TECHNIQUE);
    
    const ranks = useSelector(state => state.app.ranks);
    const techniqueTypes = useSelector(state => state.app.techniqueTypes);
    const defenses = useSelector(state => state.app.defenses);
    const repTypes = useSelector(state => state.app.repTypes);
    const dispatch = useDispatch();

    if (loading) {
        return <h4>Loading...</h4>;
    } else if (error) {
        return <h4>ERROR</h4>;
    }
    
    const technique = {
        ...data.technique,
        rankId: data.technique.rank.source_id,
        defenseId: data.technique.defense.source_id,
        typeId: data.technique.type.source_id,
        repTypeId: data.technique.rep_type.source_id
    };

    return (
        <div>
            <h2>Edit Technique</h2>
            <TechniqueForm ranks={ranks} techniqueTypes={techniqueTypes} defenses={defenses} repTypes={repTypes} initialValues={technique}
                onSubmit={technique => dispatch(saveTechnique(technique))}  />
        </div>
    );
};

export default EditTechnique;
