import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ApolloProvider } from '@apollo/client';

import { client as graphClient } from './graphClient';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import App from './app/container';
import Home from './home/component';
import ListRankTechniques from './technique/ListRankTechniques';
import ListTypeTechniques from './technique/ListTypeTechniques';
import ViewTechnique from './technique/ViewTechnique';
import NewTechnique from './technique/new/container';
import EditTechnique from './technique/edit/component';
import NewWorkout from './workout/new/container';
import ViewWorkout from './workout/view/component';
import ManageWorkouts from './workout/manage/container';
import ExportTechnique from './technique/ExportTechnique';
import TechniqueDrill from './drillInstructor/techniqueDrill/container';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={graphClient}>
      <Router>
          <App>
        <Switch>
            <Route exact path="/">
              <Home/>
            </Route>
            <Route path="/technique/export" component={ExportTechnique} />
            <Route path="/technique/rank/:rankId">
              <ListRankTechniques />
            </Route>
            <Route path="/technique/type/:techniqueTypeId">
              <ListTypeTechniques />
            </Route>
            <Route path="/technique/new" component={NewTechnique} />
            <Route path="/technique/edit/:techniqueId">
              <EditTechnique />
            </Route>
            <Route path="/technique/:techniqueId">
              <ViewTechnique />
            </Route>
            <Route path="/workout/new" component={NewWorkout} />
            <Route path="/workout/manage" component={ManageWorkouts} />
            <Route path="/workout/:workoutId" component={ViewWorkout} />
            <Route path="/drill" component={TechniqueDrill} />
        </Switch>
          </App>
      </Router>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);
