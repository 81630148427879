export const LOAD_TECHNIQUE_NAMES = 'LOAD_TECHNIQUE_NAMES';
export const LOADED_TECHNIQUE_NAMES = 'LOADED_TECHNIQUE_NAMES';

export const loadTechniqueNames = () => {
    return {
        type: LOAD_TECHNIQUE_NAMES
    };
};

export const loadedTechniqueNames = techniqueNames => {
    return {
        type: LOADED_TECHNIQUE_NAMES,
        techniqueNames
    };
};
