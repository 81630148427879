import React from 'react';

import Setup from './setup/container';
import Runner from './runner/container';

const TechniqueDrill = ({ showRunner }) => {
    return (
        <div>
            {!showRunner ? <Setup /> : <Runner />}
        </div>
    );
};

export default TechniqueDrill;
