export const ADD_TECHNIQUE_TO_WORKOUT = 'ADD_TECHNIQUE_TO_WORKOUT';
export const REORDER_WORKOUT_TECHNIQUES = 'REORDER_WORKOUT_TECHNIQUES';
export const REMOVE_TECHNIQUE_FROM_WORKOUT = 'REMOVE_TECHNIQUE_FROM_WORKOUT';
export const SAVE_WORKOUT = 'SAVE_WORKOUT';
export const SAVED_WORKOUT = 'SAVED_WORKOUT';

export const addTechniqueToWorkout = technique => {
    return {
        type: ADD_TECHNIQUE_TO_WORKOUT,
        technique
    };
};

export const reorderWorkoutTechniques = (index, movedUp) => {
    return {
        type: REORDER_WORKOUT_TECHNIQUES,
        index,
        movedUp
    };
};

export const removeTechniqueFromWorkout = index => {
    return {
        type: REMOVE_TECHNIQUE_FROM_WORKOUT,
        index
    };
};

export const saveWorkout = workout => {
    return {
        type: SAVE_WORKOUT,
        workout
    };
};

export const savedWorkout = workout => {
    return {
        type: SAVED_WORKOUT,
        workout
    };
};
