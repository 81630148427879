export const SAVE_TECHNIQUE = 'SAVE_TECHNIQUE';
export const SAVED_TECHNIQUE = 'SAVED_TECHNIQUE';
export const RESET_TECHNIQUE_FORM = 'RESET_TECHNIQUE_FORM'

export const saveTechnique = technique => {
    return {
        type: SAVE_TECHNIQUE,
        technique
    };
};

export const savedTechnique = technique => {
    return {
        type: SAVED_TECHNIQUE,
        technique
    };
};

export const resetTechniqueForm = () => {
    return {
        type: RESET_TECHNIQUE_FORM
    };
};
