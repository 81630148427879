import {
    START_DRILL, RUN_TECHNIQUE_DRILL,
    DRILL_SECOND_ELAPSED, DRILL_RUNNING_TECHNIQUE, DRILL_COMPLETE
} from './actions';

const defaultState = {
    techniques: [],
    currentTechniqueIndex: 0
};

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case START_DRILL:
            return {
                ...defaultState,
                started: true,
                secondsBetweenTechniques: action.secondsBetweenTechniques
            };

        case RUN_TECHNIQUE_DRILL:
            return {
                ...state,
                techniques: action.techniques
            };

        case DRILL_RUNNING_TECHNIQUE:
            return {
                ...state,
                currentTechniqueIndex: action.techniqueIndex,
                secondsRemaining: state.secondsBetweenTechniques
            };
        case DRILL_SECOND_ELAPSED:
            return {
                ...state,
                secondsRemaining: state.secondsRemaining - 1
            };
        case DRILL_COMPLETE:
            return {
                ...state,
                started: false
            };
        default:
            return state;
    }
};

export default reducer;
