import React, { Component } from 'react';
import { hashHistory } from 'react-router';

import TechniqueForm from '../TechniqueForm';

class NewTechnique extends Component {
    componentWillReceiveProps(nextProps) {
        const { newTechnique, resetForm } = nextProps;
        if (newTechnique.techniqueId) {
            hashHistory.push(`/technique/${newTechnique.techniqueId}`);
            resetForm();
        }
    }
    render() {
        const {
            ranks, techniqueTypes, defenses, repTypes, saveTechnique
        } = this.props;
        const initialValues = {
            steps: '1. ',
            repTypeId: 2
        };

        return (
            <div>
                <h2>New Technique</h2>
                <TechniqueForm ranks={ranks} techniqueTypes={techniqueTypes} defenses={defenses} repTypes={repTypes} onSubmit={saveTechnique} initialValues={initialValues} />
            </div>
        );
    }
};

export default NewTechnique;
