import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

class TechniquePicker extends Component {
    static propTypes = {
        ranks: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })).isRequired,
        techniqueTypes: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })).isRequired,
        defenses: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })).isRequired,
        techniques: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })).isRequired,
        onTechniqueSelected: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired
    }
    
    onSubmit(workoutTechnique) {
        workoutTechnique.typeId = parseInt(workoutTechnique.typeId);
        if (workoutTechnique.rankIsRandom) {
            // clear out dependent fields
            delete(workoutTechnique.rankId);
            workoutTechnique.techniqueIsRandom = true;
            delete(workoutTechnique.techniqueId);
        } else {
            workoutTechnique.rankId = parseInt(workoutTechnique.rankId);
            if (workoutTechnique.techniqueIsRandom) {
                // clear out dependent field
                delete(workoutTechnique.techniqueId);
            } else {
                workoutTechnique.techniqueId = parseInt(workoutTechnique.techniqueId);
            }
        }

        this.props.onTechniqueSelected(workoutTechnique);
    }

    render() {
        const {
            ranks, techniqueTypes, handleSubmit,
            rankId, typeId, rankIsRandom, techniqueIsRandom
        } = this.props;

        const filteredTechniques = this.props.techniques.filter(
            technique =>
                technique.rankId == rankId
                && technique.typeId == typeId
        );

        return (
            <form onSubmit={handleSubmit(pickedTechnique => this.onSubmit(pickedTechnique))}>
                <div className="row">
                    <div className="col-xs-6 col-md-3">
                        <div className="form-group">
                            <label htmlFor="typeId">Type</label>
                            <Field name="typeId" component="select" className="form-control">
                                <option></option>
                                {techniqueTypes.map((type, index) =>
                                    <option value={type.id} key={index}>{type.name}</option>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="col-xs-6 col-md-3">
                        <div className="checkbox">
                            <label htmlFor="rankIsRandom">
                                <Field component="input" type="checkbox" id="rankIsRandom" name="rankIsRandom" disabled={typeId == null} />
                                Random rank?
                            </label>
                        </div>
                        <div className="form-group">
                            <label htmlFor="rankId">Rank</label>
                            <Field name="rankId" component="select" className="form-control" disabled={rankIsRandom}>
                                <option></option>
                                {ranks.map((rank, index) =>
                                    <option value={rank.id} key={index}>{rank.name}</option>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="col-xs-6 col-md-3">
                        <div className="checkbox">
                            <label htmlFor="techniqueIsRandom">
                                <Field component="input" type="checkbox" id="techniqueIsRandom" name="techniqueIsRandom" disabled={rankIsRandom || !rankId} />
                                Random technique?
                            </label>
                        </div>
                        <div className="form-group">
                            <label htmlFor="techniqueId">Technique</label>
                            <Field name="techniqueId" component="select" className="form-control" disabled={rankIsRandom || !rankId || techniqueIsRandom}>
                                <option></option>
                                {filteredTechniques.map((technique, index) =>
                                    <option value={technique.id} key={index}>{technique.name}</option>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="col-xs-6 col-md-3">
                        <button className="btn btn-success" type="submit">Add Technique</button>
                    </div>
                </div>
            </form>
        );
    }
}

TechniquePicker = reduxForm({
    form: 'techniquePicker'
})(TechniquePicker);

const mapStateToProps = state => {
    const selector = formValueSelector('techniquePicker');
    const {
        rankId, typeId, rankIsRandom, techniqueIsRandom
    } = selector(state, 'rankId', 'typeId', 'rankIsRandom', 'techniqueIsRandom');
    return {
        rankId, typeId, rankIsRandom, techniqueIsRandom
    };
};

export default connect(mapStateToProps)(TechniquePicker);
