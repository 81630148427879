import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Navbar from './Navbar';
import './app.css';
import * as Realm from "realm-web";

const config = {id: 'kungfutrainer-ychab'};
const app = new Realm.App(config);

const App = ({ app: { ranks = [], techniqueTypes = [], workouts = [] }, load, children }) => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const graphToken = localStorage.getItem('graphToken');
    if (graphToken) {
      setLoggedIn(true);
      load();
    }
  }, []);

  const login = async() => {
    const body = JSON.stringify({
      "username": username,
      "password": password
    });

    let user;
    try {
      const credentials = Realm.Credentials.emailPassword(username, password);
      user = await app.logIn(credentials);
    }
    catch (e) {
      console.error(e);
      return;
    }

    localStorage.setItem('graphToken', user.storage.accessToken);
    setLoggedIn(true);
    load();
  };

  return (
    <div className="App">
      <Navbar ranks={ranks} techniqueTypes={techniqueTypes} workouts={workouts} />
      {!loggedIn && <div>
        <input type='text'  placeholder='Username' value={username} onChange={event => setUsername(event.target.value)} />
        <input type='password' placeholder='Password' onChange={event => setPassword(event.target.value)} />
        <button className="btn btn-secondary" onClick={() => login()}>Login</button>
      </div>
      }
      <div className="container">
        {children}
      </div>
    </div>
  );
}

App.propTypes = {
  app: PropTypes.shape({
    ranks: PropTypes.array.isRequired,
    techniqueTypes: PropTypes.array.isRequired
  }).isRequired,
  load: PropTypes.func.isRequired
};

export default App;
