export const generateMarkdown = technique => {
    let markdown = `${technique.name} (${technique.type.name})
${'='.repeat(technique.name.length)}

### ${technique.rank.name} ###

`;
    if (technique.defense && technique.defense.name && technique.defense.name.toLowerCase() !== 'n/a') {
        markdown += `**Defense against ** ${technique.defense.name}
        
`;
    }
    
    const steps = technique.steps.replace('\\r\\n', '\n');
    
    markdown += `${steps}
    
`;
    
    return markdown;
};