import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ManageWorkouts extends Component {
    static propTypes = {
        workouts: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })),
        removedWorkout: PropTypes.bool,
        removeWorkout: PropTypes.func.isRequired,
        reloadWorkouts: PropTypes.func.isRequired
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.removedWorkout) {
            this.props.reloadWorkouts();
        }
    }

    render() {
        const {workouts, removeWorkout} = this.props;

        return (
            <div className="table-responsive">
                <h1>Workouts</h1>
                <table className="table table-striped table-condensed">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {workouts.map((workout, index) =>
                            <tr key={index}>
                                <td>{workout.name}</td>
                                <td>
                                    <div className="btn-group">
                                        <button className="btn btn-danger" onClick={() => removeWorkout(workout.id)}>
                                            Delete
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
};

export default ManageWorkouts;
