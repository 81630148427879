import { takeLatest, call, put } from 'redux-saga/effects';

import {
    START_DRILL, RUN_TECHNIQUE_DRILL, 
    runTechniqueDrill as runTechniqueDrillAction,
    startedDrill, drillRunningTechnique, drillSecondElapsed, drillComplete
} from './actions';
import { loadDrillTechniques } from '../../../api';
import { sayPhrase, waitOneSecond } from './speech';

function* runTechniqueDrill(action) {
    try {
        for (let i = 0; i < action.techniques.length; i++) {
            const technique = action.techniques[i];
            
            yield put(drillRunningTechnique(i));
            yield call(sayPhrase, technique.name);
            
            for (let j = 0; j < action.secondsBetweenTechniques; j++) {
                yield call(waitOneSecond);
                yield put(drillSecondElapsed());
            }
        }

        yield put(drillComplete());
    } catch (error) {
        // yield put(crashReportLoadError(error));
    }
};

function* startDrill(action) {
    try {
        const techniques = yield call(loadDrillTechniques, action.startParameters);
        yield put(runTechniqueDrillAction(techniques, action.secondsBetweenTechniques));
    } catch (error) {
        // yield put(crashReportLoadError(error));
    }
}

export default function* () {
    yield takeLatest(START_DRILL, startDrill);
    yield takeLatest(RUN_TECHNIQUE_DRILL, runTechniqueDrill);
};
