import { REMOVED_WORKOUT } from './actions';
import { LOADED_WORKOUTS } from '../../app/actions';

const manageWorkouts = (state = {}, action) => {
    switch (action.type) {
        case REMOVED_WORKOUT:
            return {
                removedWorkout: true
            };
        case LOADED_WORKOUTS:
            return {};
        default:
            return state;
    }
};

export default manageWorkouts;
