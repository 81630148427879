export const LOAD_TECHNIQUE_COUNTS = 'LOAD_TECHNIQUE_COUNTS';
export const LOADED_TECHNIQUE_COUNTS = 'LOADED_TECHNIQUE_COUNTS';
export const TOGGLE_RANK = 'TOGGLE_RANK';
export const CHANGE_TECHNIQUE_TYPE = 'CHANGE_TECHNIQUE_TYPE';
export const CHANGE_SECONDS_BETWEEN_TECHNIQUES = 'CHANGE_SECONDS_BETWEEN_TECHNIQUES';
export const CHANGE_NUMBER_OF_TECHNIQUES = 'CHANGE_NUMBER_OF_TECHNIQUES';

export const loadTechniqueCounts = () => {
    return {
        type: LOAD_TECHNIQUE_COUNTS
    };
};

export const loadedTechniqueCounts = techniqueCounts => {
    return {
        type: LOADED_TECHNIQUE_COUNTS,
        techniqueCounts
    };
};

export const toggleRank = rankId => {
    return {
        type: TOGGLE_RANK,
        rankId
    };
};

export const changeTechniqueType = techniqueTypeId => {
    return {
        type: CHANGE_TECHNIQUE_TYPE,
        techniqueTypeId
    };
};

export const changeSecondsBetweenTechniques = secondsBetweenTechniques => {
    return {
        type: CHANGE_SECONDS_BETWEEN_TECHNIQUES,
        secondsBetweenTechniques
    };
};

export const changeNumberOfTechniques = numberOfTechniques => {
    return {
        type: CHANGE_NUMBER_OF_TECHNIQUES,
        numberOfTechniques
    };
};
