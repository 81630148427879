import { gql } from '@apollo/client';
import { client } from '../../graphClient';
import moment from 'moment';

const COMLETE_WORKOUT = gql`
    mutation CompleteWorkout($workoutId: ObjectId!, $completionTime: DateTime!) {
        updateOneWorkout(
            query: {completed_time_exists: false, _id: $workoutId}
            set: {is_completed: true, completed_time: $completionTime}
        ) {
            is_completed
            completed_time
            techniques {
                technique_id
            }
        }
    }
`;

const COMLETE_TECHNIQUES = gql`
    mutation CompleteTechniques($techniqueIds: [Int!]!, $notebookId: Int) {
        updateManyTechniques(
           query: {source_id_in: $techniqueIds, notebook_id: {source_id: $notebookId } }
           set: {workout_complete: true}
        ) {
           matchedCount
        }
    }
`;

export const completeWorkout = async (workoutId, notebookId) => {
    // increment reps_completed for techniques
    // delete workout
    const completionTime = moment(new Date()).format();
    const { data } = await client.mutate({
        mutation: COMLETE_WORKOUT,
        variables: { workoutId, completionTime }
    });
    
    const completedTechniqueIds = data.updateOneWorkout.techniques.map(t => t.technique_id);
    
    const createResponse = await client.mutate({
        mutation: COMLETE_TECHNIQUES,
        variables: { notebookId, techniqueIds: completedTechniqueIds }
    });
};


const ABANDON_WORKOUT = gql`
    mutation AbandonWorkout($workoutId: ObjectId!) {
        deleteOneWorkout(query: {_id: $workoutId}) {
            _id
        }
    }
`;

export const abandonWorkout = async (workoutId) => {
    const { data } = await client.mutate({
        mutation: ABANDON_WORKOUT,
        variables: { workoutId }
    });
};
