import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

let TechniqueList = ({techniques, secondColumnSelector}) => {
    return (
        <div className="table-responsive">
            <table className="table table-striped table-condensed">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {techniques.map((technique, index) =>
                        <tr key={index}>
                            <td>{technique.name}</td>
                            <td>{secondColumnSelector(technique)}</td>
                            <td>
                                <div className="btn-group">
                                    <Link to={`/technique/${technique.id}`} className="btn btn-primary">View</Link>
                                    <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                        <span className="caret"></span>
                                    </button>
                                    <ul className="dropdown-menu" role="menu">
                                        <li><Link to={`/technique/edit/${technique.id}`}>Edit</Link></li>
                                        <li><Link to={`/technique/delete/${technique.id}`}>Delete</Link></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

TechniqueList.propTypes = {
    techniques: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.shape({
            name: PropTypes.string.isRequired
        })
    })),
    secondColumnSelector: PropTypes.func.isRequired
};

export default TechniqueList;
