import React, { useEffect } from 'react';
import { Link, withRouter, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import ReactMarkdown from 'react-markdown';

import { generateMarkdown } from './utils';

const TechniqueView = () => {
    
    const { techniqueId } = useParams();
    
    const LOAD_TECHNIQUE = gql`
        query {
            technique(query: {source_id: ${techniqueId}}) {
                name
                steps
                rank: rank_id {
                    name
                }
                type: type_id {
                    name
                }
                defense: defense_id {
                    name
                }
            }
        }
    `;
    
    const { loading, error, data } = useQuery(LOAD_TECHNIQUE);

    let markdown = '';
    if (loading) {
        markdown = '###Loading...'
    } else if (error) {
        markdown = `###ERROR: ${error}`
    } else {
        markdown = generateMarkdown(data.technique);
    }
    
    return (
        <div>
            <ReactMarkdown source={markdown} />
            <Link className="btn btn-primary" to={`/technique/edit/${techniqueId}`}>Edit</Link>
        </div>
    );
};

export default withRouter(TechniqueView);
