import { connect } from 'react-redux';

import App from './component';
import {
    loadRanks, loadTechniqueTypes,
    loadDefenses, loadRepTypes,
    loadWorkouts
} from './actions';

const mapStateToProps = state => {
    return {
        app: state.app
    };
};

const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            dispatch(loadRanks());
            dispatch(loadTechniqueTypes());
            dispatch(loadDefenses());
            dispatch(loadRepTypes());
            dispatch(loadWorkouts());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
