import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import TechniqueList from './TechniqueList';

const LOAD_TECHNIQUES = gql `
    query ListRankTechniques($rankId: Int!) {
        rank(query: { source_id: $rankId }) {
            name
        }
        techniques(query: { rank_id: { source_id: $rankId } }) {
            source_id
            name
            type: type_id {
                name
            }
        }
    }
`;

const ListRankTechniques = props => {
    const { rankId } = useParams();

    const { loading, error, data } = useQuery(LOAD_TECHNIQUES, { variables: { rankId } });

    if (loading) {
        return <h4>Loading...</h4>;
    }
    else if (error) {
        return <h4>ERROR</h4>;
    }

    const techniques = data.techniques.map(t => ({ ...t, id: t.source_id }));

    return (
        <div>
            <h2>{data.rank.name}</h2>
            <TechniqueList techniques={techniques}
                secondColumnSelector={technique => technique.type.name} />
        </div>
    );
};

export default ListRankTechniques;
