import { connect } from 'react-redux';

import NewTechnique from './component';
import { saveTechnique, resetTechniqueForm } from './actions';

const mapStateToProps = state => {
    return {
        ranks: state.app.ranks,
        techniqueTypes: state.app.techniqueTypes,
        defenses: state.app.defenses,
        repTypes: state.app.repTypes,
        newTechnique: state.newTechnique
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveTechnique: technique => dispatch(saveTechnique(technique)),
        resetForm: () => dispatch(resetTechniqueForm())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTechnique);
