import { takeLatest, call, put } from 'redux-saga/effects';

import {
    LOAD_TECHNIQUE_NAMES, loadedTechniqueNames
} from './actions'
import { fetchTechniqueNames } from '../../api';

function* loadTechniqueNames(action) {
    try {
        const techniqueNames = yield call(fetchTechniqueNames);
        yield put(loadedTechniqueNames(techniqueNames));
    } catch (error) {
        // yield put(crashReportLoadError(error));
    }
};

function* app() {
    yield takeLatest(LOAD_TECHNIQUE_NAMES, loadTechniqueNames);
};

export default app;