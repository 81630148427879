export const waitOneSecond = () =>
    new Promise(resolve => setTimeout(resolve, 1000));

export const sayPhrase = (phrase) => {
    return new Promise(resolve => {
        const utterance = new SpeechSynthesisUtterance(phrase);
        utterance.onend = resolve;

        /*
         * store utterance somewhere so it doesn't get GC'd before onend is called ... :(
         * http://stackoverflow.com/questions/23483990/speechsynthesis-api-onend-callback-not-working
         */
        window.utterNonSense = [];
        window.utterNonSense.push(utterance);

        speechSynthesis.speak(utterance);
    });
};
