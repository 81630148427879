import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Link, useParams, useHistory } from 'react-router-dom';
import buildWorkoutCommand from '../commands/buildWorkout';
import { completeWorkout as completeWorkoutCommand, abandonWorkout as abandonWorkoutCommand } from '../commands/completeWorkout';
import moment from 'moment';

import './viewWorkout.css';


const QUERY_ACTIVE_WORKOUT = gql`
    query ActiveWorkout($workoutId: Int!) {
        workoutConfig: workout_config(query: {source_id: $workoutId}) {
            name
        }
        workout (query: {workout_config_id: $workoutId, completed_time_exists: false}) {
            _id
            start_time
            techniques {
                technique_id 
            }
        }
    }
`;

const QUERY_TECHNIQUES = gql`
    query TechniquesByIds($techniqueIds: [Int!]!) {
        techniques(query: {source_id_in: $techniqueIds}) {
            name
            source_id
            type_id {
                name
            }
        }
    }
`;

const ViewWorkout = props => {
    // HOOKS
    const { workoutId } = useParams();
    const notebookId = useSelector(state => state.app.notebookId);
    const [buildingWorkout, setBuildingWorkout] = useState(false);
    const history = useHistory();

    const { 
        loading: workoutLoading, 
        error: workoutError, 
        data: workoutData, 
        refetch: refetchWorkout 
    } = useQuery(QUERY_ACTIVE_WORKOUT, { variables: { workoutId } });

    const techniqueIds = workoutData?.workout?.techniques?.map(t => t.technique_id);
    const { 
        loading: techniquesLoading, 
        error: techniquesError, 
        data: techniquesData,
        refetch: refetchTechniques
    } = useQuery(QUERY_TECHNIQUES, { 
        variables: { techniqueIds }, 
        skip: workoutLoading || !workoutData?.workout
    });

    useEffect(async () => {
        if (workoutLoading || workoutError || workoutData && workoutData?.workout?.start_time) {
            return;
        }
        
        setBuildingWorkout(true);
        await buildWorkoutCommand(workoutId, notebookId);
        setBuildingWorkout(false);
        refetchWorkout();
        refetchTechniques();
    }, [workoutLoading, workoutError, workoutData]);
    
    
    // FUNCTIONS
    const completeWorkout = async () => {
        await completeWorkoutCommand(workoutData.workout._id, notebookId);
        history.push('/');
    };
    
    const abandonWorkout = async () => {
        await abandonWorkoutCommand(workoutData.workout._id);
        history.push('/');
    };
    
    // RENDER
    if (workoutError || techniquesError) {
        return <h4>ERROR: {JSON.stringify(workoutError || techniquesError)}</h4>;
    }
    else if (workoutLoading || techniquesLoading || buildingWorkout || !workoutData?.workout) {
        return <h4>Loading...</h4>;
    }
    
    const startTime = workoutData?.workout?.start_time.match(/z$/i)
        ? workoutData?.workout?.start_time
        : workoutData?.workout?.start_time + 'z';
        
    const techniques = [...techniquesData.techniques];
    techniques.sort((t1, t2) => {
        const t1Index = workoutData.workout.techniques.findIndex(t => t.technique_id === t1.source_id);
        const t2Index = workoutData.workout.techniques.findIndex(t => t.technique_id === t2.source_id);
        return t1Index - t2Index;
    });

    return (
        <div className="table-responsive">
            <h1>{workoutData.workoutConfig.name}</h1>
            <h4 className="workout-start-time">
                Started on {moment(startTime).format("dddd, MMMM Do YYYY, h:mm a")}
            </h4>
            <table className="table table-striped table-condensed">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {techniques.map((technique, index) =>
                        <tr key={index}>
                            <td>{technique.name}</td>
                            <td>{technique.type_id.name}</td>
                            <td>
                                <div className="btn-group">
                                    <Link to={`/technique/${technique.source_id}`} target="_blank" className="btn btn-primary">View</Link>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <div className="btn-group">
                <button className="btn btn-success btn-lg" onClick={() => completeWorkout()}>
                    Finish
                </button>
                <button className="btn btn-danger btn-lg" onClick={() => abandonWorkout()}>
                    Abandon
                </button>
            </div>
        </div>
    );
};

export default ViewWorkout;
