const rootReducer = (state = {}, action) => {
    if (action == null) {
        return { ...state };
    }

    switch (action.type) {
        default:
            return { ...state };
    }
};

export default rootReducer;
