const apiRoot = process.env.NODE_ENV === 'development'
  ? 'http://localhost:50404/api'
  : '/api';

const buildJsonRequest = (method, payload) => {
    return {
        method: method,
        body: JSON.stringify(payload),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
};

export const fetchTechnique = techniqueId => {
    return fetch(`${apiRoot}/technique/${techniqueId}`)
        .then(response => {
            return response.json();
        })
        .then(json => {
            return json;
        });
};

export const fetchTechniqueNames = () => {
    return fetch(`${apiRoot}/technique/names`)
        .then(response => {
            return response.json();
        })
        .then(json => {
            return json;
        });
};

export const fetchTechniqueMarkdown = techniqueId => {
    return fetch(`${apiRoot}/technique/markdown/${techniqueId}`)
        .then(response => {
            return response.text();
        })
        .then(markdown => {
            return markdown;
        });
};

export const postTechnique = technique => {
    return fetch(`${apiRoot}/technique`,
        buildJsonRequest('POST', technique))
        .then(response => {
            return response.json();
        })
        .then(json => {
            return json;
        });
};

export const postWorkout = workout => {
    return fetch(`${apiRoot}/workoutconfig`,
        buildJsonRequest('POST', workout))
        .then(response => {
            return response.json();
        })
        .then(json => {
            return json;
        });
};

export const fetchTechniquesByType = techniqueTypeId => {
    return fetch(`${apiRoot}/technique/type/${techniqueTypeId}`)
        .then(response => {
            return response.json();
        })
        .then(json => {
            return json;
        });
};

export const fetchTechniquesByRank = rankId => {
    return fetch(`${apiRoot}/technique/rank/${rankId}`)
        .then(response => {
            return response.json();
        })
        .then(json => {
            return json;
        });
};

export const fetchTechniqueTypes = () => {
    return fetch(`${apiRoot}/techniquetype`)
        .then(response => {
            return response.json();
        })
        .then(json => {
            return json;
        });
};

export const fetchRanks = () => {
    return fetch(`${apiRoot}/rank`)
        .then(response => {
            return response.json();
        })
        .then(json => {
            return json;
        });
};

export const fetchRepTypes = () => {
    return fetch(`${apiRoot}/reptype`)
        .then(response => {
            return response.json();
        })
        .then(json => {
            return json;
        });
};

export const fetchDefenses = () => {
    return fetch(`${apiRoot}/defense`)
        .then(response => {
            return response.json();
        })
        .then(json => {
            return json;
        });
};

export const fetchWorkouts = () => {
    return fetch(`${apiRoot}/workoutconfig`)
        .then(response => {
            return response.json();
        })
        .then(json => {
            return json;
        });
};

export const fetchWorkout = workoutId => {
    return fetch(`${apiRoot}/workout/${workoutId}`)
        .then(response => {
            return response.json();
        })
        .then(json => {
            return json;
        });
};

export const getFinishWorkout = workoutId => {
    return fetch(`${apiRoot}/workout/finish/${workoutId}`)
        .then(response => {
            return;
        });
};

export const deleteWorkout = workoutId => {
    return fetch(`${apiRoot}/workout/${workoutId}`, { method: 'delete' })
        .then(response => {
            return;
        });
};

export const deleteWorkoutConfig = workoutId => {
    return fetch(`${apiRoot}/workoutconfig/${workoutId}`, { method: 'delete' })
        .then(response => {
            return;
        });
};

export const loadDrillTechniques = parameters => {
    return fetch(`${apiRoot}/techniqueDrill`,
        buildJsonRequest('POST', parameters))
        .then(response => {
            return response.json();
        })
        .then(json => {
            return json;
        });
};

export const getTechniqueCounts = () => {
    return fetch(`${apiRoot}/technique/counts`)
        .then(response => {
            return response.json();
        })
        .then(json => {
            return json;
        });
};

export const exportTechniqueUrl = () => `${apiRoot}/technique/export`;
