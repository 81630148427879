import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { gql, useQuery } from '@apollo/client';

import TechniqueList from './TechniqueList';

const LOAD_TECHNIQUES = gql `
    query ListTypeTechniques($techniqueTypeId: Int!) {
        technique_type(query: { source_id: $techniqueTypeId }) {
            name
        }
        techniques(query: { type_id:{ source_id: $techniqueTypeId } }) {
            source_id
            name
            rank: rank_id {
                name
            }
        }
    }
`;

const ListTypeTechniques = props => {
    const { techniqueTypeId } = useParams();

    const { loading, error, data } = useQuery(LOAD_TECHNIQUES, { variables: { techniqueTypeId } });

    if (loading) {
        return <h4>Loading...</h4>;
    }
    else if (error) {
        return <h4>ERROR</h4>;
    }

    const techniques = data.techniques.map(t => ({ ...t, id: t.source_id }));

    return (
        <div>
            <h2>{data.technique_type.name}</h2>
            <TechniqueList techniques={techniques}
                secondColumnSelector={technique => technique.rank.name} />
        </div>
    );
};

export default ListTypeTechniques;
