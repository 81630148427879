export const LOAD_RANKS = 'LOAD_RANKS';
export const LOADED_RANKS = 'LOADED_RANKS';
export const LOAD_TECHNIQUE_TYPES = 'LOAD_TECHNIQUE_TYPES';
export const LOADED_TECHNIQUE_TYPES = 'LOADED_TECHNIQUE_TYPES';
export const LOAD_DEFENSES = 'LOAD_DEFENSES';
export const LOADED_DEFENSES = 'LOADED_DEFENSES';
export const LOAD_REP_TYPES = 'LOAD_REP_TYPES';
export const LOADED_REP_TYPES = 'LOADED_REP_TYPES';
export const LOAD_WORKOUTS = 'LOAD_WORKOUTS';
export const LOADED_WORKOUTS = 'LOADED_WORKOUTS';

export const loadRanks = () => {
    return {
        type: LOAD_RANKS
    };
};

export const loadTechniqueTypes = () => {
    return {
        type: LOAD_TECHNIQUE_TYPES
    };
};

export const loadedRanks = (ranks=[]) => {
    return {
        type: LOADED_RANKS,
        ranks
    };
};

export const loadedTechniqueTypes = (techniqueTypes=[]) => {
    return {
        type: LOADED_TECHNIQUE_TYPES,
        techniqueTypes
    };
};

export const loadDefenses = () => {
    return {
        type: LOAD_DEFENSES
    };
};

export const loadRepTypes = () => {
    return {
        type: LOAD_REP_TYPES
    };
};

export const loadedDefenses = (defenses=[]) => {
    return {
        type: LOADED_DEFENSES,
        defenses
    };
};

export const loadedRepTypes = (repTypes=[]) => {
    return {
        type: LOADED_REP_TYPES,
        repTypes
    };
};

export const loadWorkouts = () => {
    return {
        type: LOAD_WORKOUTS
    };
};

export const loadedWorkouts = (workouts=[]) => {
    return {
        type: LOADED_WORKOUTS,
        workouts
    };
};