import {
    TOGGLE_RANK, CHANGE_TECHNIQUE_TYPE,
    CHANGE_SECONDS_BETWEEN_TECHNIQUES, CHANGE_NUMBER_OF_TECHNIQUES,
    LOAD_TECHNIQUE_COUNTS, LOADED_TECHNIQUE_COUNTS
} from './actions';

const calculateMaxNumberOfTechniques = (selectedTechniqueTypeId, selectedRanks, techniqueCounts) => {
    let maxNumberOfTechniques = 0;
    if (selectedTechniqueTypeId <= 0 || !selectedRanks.size) {
        return maxNumberOfTechniques;
    }

    for (const rankId of selectedRanks) {
        if (techniqueCounts[rankId] && techniqueCounts[rankId][selectedTechniqueTypeId]) {
            maxNumberOfTechniques += techniqueCounts[rankId][selectedTechniqueTypeId];
        }
    }

    return maxNumberOfTechniques;
}

const defaultState = {
    ranks: [],
    techniqueTypes: [],
    selectedRanks: new Set(),
    secondsBetweenTechniques: 5,
    isLoading: true
};

const reducer = (state = defaultState, action) => {
    let maxNumberOfTechniques;

    switch (action.type) {
        case LOAD_TECHNIQUE_COUNTS:
            return defaultState;

        case LOADED_TECHNIQUE_COUNTS:
            return {
                ...state,
                isLoading: false,
                techniqueCounts: action.techniqueCounts
            };

        case TOGGLE_RANK:
            let selectedRanks = new Set(state.selectedRanks);
            if (selectedRanks.has(action.rankId)) {
                selectedRanks.delete(action.rankId);
            } else {
                selectedRanks.add(action.rankId);
            }

            maxNumberOfTechniques = calculateMaxNumberOfTechniques(state.selectedTechniqueTypeId, selectedRanks, state.techniqueCounts);

            return {
                ...state,
                selectedRanks,
                maxNumberOfTechniques,
                numberOfTechniques: maxNumberOfTechniques
            };

        case CHANGE_TECHNIQUE_TYPE:
            maxNumberOfTechniques = calculateMaxNumberOfTechniques(action.techniqueTypeId, state.selectedRanks, state.techniqueCounts);

            return {
                ...state,
                selectedTechniqueTypeId: action.techniqueTypeId,
                maxNumberOfTechniques,
                numberOfTechniques: maxNumberOfTechniques
            };

        case CHANGE_SECONDS_BETWEEN_TECHNIQUES:
            return {
                ...state,
                secondsBetweenTechniques: action.secondsBetweenTechniques
            };

        case CHANGE_NUMBER_OF_TECHNIQUES:
            return {
                ...state,
                numberOfTechniques: action.numberOfTechniques
            };

        default:
            return state;
    }
};

export default reducer;
