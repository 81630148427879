import { connect } from 'react-redux';

import TechniqueDrill from './component';

const mapStateToProps = state => {
    return {
        showRunner: state.techniqueDrill.runner.started
    };
};

export default connect(mapStateToProps)(TechniqueDrill);
