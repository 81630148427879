import { connect } from 'react-redux';

import ManageWorkouts from './component';
import { removeWorkout } from './actions';
import { loadWorkouts } from '../../app/actions';

const mapStateToProps = state => {
    return {
        workouts: state.app.workouts,
        removedWorkout: state.manageWorkouts.removedWorkout
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeWorkout: workoutId => dispatch(removeWorkout(workoutId)),
        reloadWorkouts: () => dispatch(loadWorkouts())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageWorkouts);
