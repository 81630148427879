import React, { Component } from 'react';

class TechniqueDrill extends Component {
    render() {
        const {
            techniques, currentTechniqueIndex, secondsRemaining
        } = this.props;

        if (!techniques || !techniques.length) {
            return <div>Loading...</div>;
        }

        const currentTechnique = techniques[currentTechniqueIndex];

        const runningLastTechnique = currentTechniqueIndex + 1 >= techniques.length;
        let nextTechnique = null,
            remainingTechniqueCount = null;

        return (
            <div className="technique-drill-container">
                <h2>{currentTechnique.type.name} Drill</h2>
                <p>{currentTechnique.name}</p>
                <p>{secondsRemaining} seconds</p>
                {!runningLastTechnique &&
                    <div>
                        <p>{techniques[currentTechniqueIndex + 1].name} next</p>
                        <p>{techniques.length - currentTechniqueIndex - 1} techniques remaining</p>
                    </div>
                }
            </div>
        );
    }
}

export default TechniqueDrill;
