import { connect } from 'react-redux';

import NewWorkout from './component';
import { loadWorkouts } from '../../app/actions';
import { loadTechniqueNames } from '../../technique/picker/actions';
import { addTechniqueToWorkout, reorderWorkoutTechniques, removeTechniqueFromWorkout, saveWorkout } from './actions';

const mapStateToProps = state => {
    return {
        ranks: state.app.ranks,
        techniqueTypes: state.app.techniqueTypes,
        defenses: state.app.defenses,
        newWorkout: state.newWorkout
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadTechniques: () => dispatch(loadTechniqueNames()),
        onTechniqueSelected: technique => dispatch(addTechniqueToWorkout(technique)),
        reorderTechniques: techniques => dispatch(reorderWorkoutTechniques(techniques)),
        onTechniqueRemoved: technique => dispatch(removeTechniqueFromWorkout(technique)),
        onTechniqueReordered: (index, movedUp) => dispatch(reorderWorkoutTechniques(index, movedUp)),
        saveWorkout: workout => dispatch(saveWorkout(workout)),
        reloadWorkouts: () => dispatch(loadWorkouts())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewWorkout);