import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

class TechniqueForm extends Component {
    static propTypes = {
        ranks: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })).isRequired,
        techniqueTypes: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })).isRequired,
        defenses: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })).isRequired,
        repTypes: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })).isRequired,
        onSubmit: PropTypes.func.isRequired
    };
 
    onSubmit(technique) {
        technique.rankId = parseInt(technique.rankId);
        technique.typeId = parseInt(technique.typeId);
        technique.reps = parseInt(technique.reps);
        technique.repTypeId = parseInt(technique.repTypeId);

        if (technique.defenseId) {
            technique.defenseId = parseInt(technique.defenseId);
        }
        this.props.onSubmit(technique);
    }
 
    render() {
        let {
            ranks, techniqueTypes, defenses, repTypes, handleSubmit
        } = this.props;

        return (
            <form onSubmit={handleSubmit(technique => this.onSubmit(technique))}>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <Field id="name" component="input" className="form-control" name="name" type="text" />
                </div>
                <div className="row">
                    <div className="col-xs-6 col-md-4">
                        <div className="form-group">
                            <label htmlFor="rankId">Rank</label>
                            <Field name="rankId" component="select" className="form-control">
                                <option></option>
                                {ranks.map((rank, index) =>
                                    <option value={rank.id} key={index}>{rank.name}</option>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="col-xs-6 col-md-4">
                        <div className="form-group">
                            <label htmlFor="typeId">Type</label>
                            <Field name="typeId" component="select" className="form-control">
                                <option></option>
                                {techniqueTypes.map((type, index) =>
                                    <option value={type.id} key={index}>{type.name}</option>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="col-xs-6 col-md-4">
                        <div className="form-group">
                            <label htmlFor="defenseId">Defense</label>
                            <Field name="defenseId" component="select" className="form-control">
                                <option></option>
                                {defenses.map((defense, index) =>
                                    <option value={defense.id} key={index}>{defense.name}</option>
                                )}
                            </Field>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-6 col-md-4">
                        <div className="form-group">
                            <label htmlFor="every_workout">Every Workout?</label>
                            <input type="checkbox" id="every_workout" name="every_workout" className="checkbox" />
                        </div>
                    </div>
                    <div className="col-xs-6 col-md-4">
                        <div className="form-group">
                            <label htmlFor="reps">Repetitions</label>
                            <Field component="input" type="number" step="any" id="reps" name="reps" className="form-control" />
                        </div>
                    </div>
                    <div className="col-xs-6 col-md-4">
                        <div className="form-group">
                            <label htmlFor="repTypeId">Rep units</label>
                            <Field name="repTypeId" component="select" className="form-control">
                                {repTypes.map((repType, index) =>
                                    <option value={repType.id} key={index}>{repType.name}</option>
                                )}
                            </Field>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <Field name="steps" component="textarea" className="form-control" value={'1.'} rows="20" />
                </div>
                <div className="form-group">
                    <button type="submit" className="btn btn-primary">Save</button>
                </div>
            </form>
        );
    }
}

TechniqueForm = reduxForm({
    form: 'technique'
})(TechniqueForm);

export default TechniqueForm;
