import {
    LOADED_TECHNIQUE_TYPES, LOADED_RANKS,
    LOADED_DEFENSES, LOADED_REP_TYPES,
    LOADED_WORKOUTS
} from './actions';

const app = (state = {
    techniqueTypes: [],
    ranks: [],
    defenses: [],
    repTypes: [],
    workouts: [],
    notebookId: 1
}, action) => {
    if (!action) {
        return { ...state };
    }

    switch (action.type) {
        case LOADED_TECHNIQUE_TYPES:
            return {
                ...state,
                techniqueTypes: action.techniqueTypes
            };
        case LOADED_RANKS:
            return {
                ...state,
                ranks: action.ranks
            };
        case LOADED_DEFENSES:
            return {
                ...state,
                defenses: action.defenses
            };
        case LOADED_REP_TYPES:
            return {
                ...state,
                repTypes: action.repTypes
            };
        case LOADED_WORKOUTS:
            return {
                ...state,
                workouts: action.workouts
            }
        default:
            return { ...state };
    }
};

export default app;
