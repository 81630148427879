import { takeLatest, call, put } from 'redux-saga/effects';

import {
    LOAD_TECHNIQUE_COUNTS, loadedTechniqueCounts
} from './actions';
import { getTechniqueCounts } from '../../../api';

function* loadTechniqueCounts(action) {
    try {
        const techniqueCounts = yield call(getTechniqueCounts);
        yield put(loadedTechniqueCounts(techniqueCounts));
    } catch (error) {
        // yield put(crashReportLoadError(error));
    }
}

export default function* () {
    yield takeLatest(LOAD_TECHNIQUE_COUNTS, loadTechniqueCounts);
};
