import { takeLatest, call, put } from 'redux-saga/effects';

import { SAVE_TECHNIQUE, savedTechnique } from './actions'
import { postTechnique } from '../../api';

function* saveTechnique(action) {
    try {
        const technique = yield call(postTechnique, action.technique);
        yield put(savedTechnique(technique));
    } catch (error) {
        // yield put(crashReportLoadError(error));
    }
};

function* newTechnique() {
    yield takeLatest(SAVE_TECHNIQUE, saveTechnique);
};

export default newTechnique;