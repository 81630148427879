import { SAVED_TECHNIQUE, RESET_TECHNIQUE_FORM } from './actions';

const newTechnique = (state = {}, action) => {
    switch (action.type) {
        case SAVED_TECHNIQUE:
            return {
                ...state,
                techniqueId: action.technique.id
            };
        case RESET_TECHNIQUE_FORM:
            return {};
        default:
            return state;
    }
};

export default newTechnique;
