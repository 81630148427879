import { connect } from 'react-redux';

import Runner from './component';

const mapStateToProps = state => {
    return {
        techniques: state.techniqueDrill.runner.techniques,
        currentTechniqueIndex: state.techniqueDrill.runner.currentTechniqueIndex,
        secondsRemaining: state.techniqueDrill.runner.secondsRemaining
    };
};


export default connect(mapStateToProps)(Runner);
