import { useSelector, useDispatch } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { client } from '../../graphClient';
import moment from 'moment';


const GET_WORKOUT_CONFIG = gql `
    query GetWorkoutConfig($workoutId: Int!) {
        workoutConfig: workout_config(query: {source_id: $workoutId}) {
            name
        }
        techniqueConfigs: workout_config_techniques(
            query: {workout_config_id: {source_id: $workoutId}}
        ) {
            type_id {
                source_id
                position
            }
            rank_is_random
            technique_is_random
            rank_id {
              source_id
            }
            technique_id {
                source_id
                name
                type_id {
                    name
                }
            }
        }
    }
`;

const GET_TECHNIQUES_OF_TYPES = gql `
    query TechniquesOfTypes($typeIds: [Int!]!, $notebookId: Int!) {
        techniques(
            query: {type_id: {source_id_in: $typeIds}, notebook_id: {source_id: $notebookId}}
            limit: 500
        ) {
            name
            source_id
            type_id {
                source_id
                name
            }
            rank_id {
                source_id
            }
        }
    }
`;

const INSERT_WORKOUT = gql`
    mutation InsertWorkout($workout: WorkoutInsertInput!) {
        insertOneWorkout(data: $workout) {
            _id
        }
    }
`;


const buildWorkout = async(workoutId, notebookId) => {
    const { data: workoutConfigData } = await client.query({
        query: GET_WORKOUT_CONFIG,
        variables: { workoutId: parseInt(workoutId) }
    });

    const typeIds = new Set();
    const techniqueIds = new Set();
    workoutConfigData.techniqueConfigs.forEach(tc => {
        const typeId = tc.type_id.source_id;
        typeIds.add(typeId);
        if (tc?.technique_id?.source_id) techniqueIds.add(tc.technique_id.source_id);
    });

    const { data: techniquesData } = await client.query({
        query: GET_TECHNIQUES_OF_TYPES,
        variables: { typeIds: Array.from(typeIds), notebookId },
        limit: 500
    });
    
    const techniques = [...techniquesData.techniques];
    techniques.sort(_ => Math.random() - 0.5);
    const workoutTechniques = [];

    for (const techniqueConfig of workoutConfigData.techniqueConfigs) {
        let technique;
        
        // fixed technique
        if (techniqueConfig.technique_id?.source_id) {
            technique = techniques.find(t => t.source_id === techniqueConfig.technique_id.source_id)
        }
        
        // random of rank & type
        else if (techniqueConfig.rank_id?.source_id) {
            technique = techniques.find(t => 
                t.rank_id.source_id === techniqueConfig.rank_id.source_id
                && t.type_id.source_id === techniqueConfig.type_id.source_id
                && !techniqueIds.has(t.source_id));
            console.warn(`Could not find eligible technique for ${techniqueConfig}`);
        }
        
        // random of type
        else {
            technique = techniques.find(t =>
                t.type_id.source_id === techniqueConfig.type_id.source_id
                && !techniqueIds.has(t.source_id));
            console.warn(`Could not find eligible technique for ${techniqueConfig}`);
        }
        
        workoutTechniques.push(technique);
        techniqueIds.add(technique.source_id);
    }
    workoutTechniques.sort((t1, t2) => t1.type_id.position - t2.type_id.position);
    const workout = {
        workout_config_id: workoutId,
        start_time: moment(new Date()).format(),//'YYYY-MM-DD HH:mm:ss'),
        techniques: workoutTechniques
            .map(t => ({
                technique_id: t.source_id
            }))
    };

    const createResponse = await client.mutate({
        mutation: INSERT_WORKOUT,
        variables: { workout }
    });    
};

export default buildWorkout;
