import { takeLatest, call, put } from 'redux-saga/effects';

import { REMOVE_WORKOUT, removedWorkout } from './actions'
import { deleteWorkoutConfig } from '../../api';

function* removeWorkout(action) {
    try {
        yield call(deleteWorkoutConfig, action.workoutId);
        yield put(removedWorkout());
    } catch (error) {
        // yield put(crashReportLoadError(error));
    }
};

function* manageWorkouts() {
    yield takeLatest(REMOVE_WORKOUT, removeWorkout);
};

export default manageWorkouts;