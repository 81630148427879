import { takeLatest, call, put } from 'redux-saga/effects';

import { SAVE_WORKOUT, savedWorkout } from './actions'
import { postWorkout } from '../../api';

function* saveWorkout(action) {
    try {
        const workout = yield call(postWorkout, action.workout);
        yield put(savedWorkout(workout));
    } catch (error) {
        // yield put(crashReportLoadError(error));
    }
};

function* workout() {
    yield takeLatest(SAVE_WORKOUT, saveWorkout);
};

export default workout;