import { connect } from 'react-redux';

import {
    loadTechniqueCounts, toggleRank, changeTechniqueType, 
    changeSecondsBetweenTechniques, changeNumberOfTechniques
} from './actions';
import { startDrill } from '../runner/actions';
import TechniqueDrillSetup from './component';

const mapStateToProps = state => {
    return {
        isLoading: state.techniqueDrill.setup.isLoading,
        ranks: state.app.ranks,
        techniqueTypes: state.app.techniqueTypes,
        selectedTechniqueTypeId: state.techniqueDrill.setup.selectedTechniqueTypeId,
        selectedRanks: state.techniqueDrill.setup.selectedRanks,
        secondsBetweenTechniques: state.techniqueDrill.setup.secondsBetweenTechniques,
        numberOfTechniques: state.techniqueDrill.setup.numberOfTechniques,
        maxNumberOfTechniques: state.techniqueDrill.setup.maxNumberOfTechniques
    };
};

const mapDispatchToProps = dispatch => {
    return {
        load: () => 
            dispatch(loadTechniqueCounts()),
        toggleRank: rankId =>
            dispatch(toggleRank(rankId)),
        changeTechniqueType: techniqueTypeId =>
            dispatch(changeTechniqueType(parseInt(techniqueTypeId))),
        changeSecondsBetweenTechniques: secondsBetweenTechniques =>
            dispatch(changeSecondsBetweenTechniques(parseInt(secondsBetweenTechniques))),
        changeNumberOfTechniques: numberOfTechniques => 
            dispatch(changeNumberOfTechniques(parseInt(numberOfTechniques))),
        startDrill: (techniqueTypeId, selectedRanksIds, numberOfTechniques, secondsBetweenTechniques) =>
            dispatch(startDrill(techniqueTypeId, selectedRanksIds, numberOfTechniques, secondsBetweenTechniques))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TechniqueDrillSetup);
