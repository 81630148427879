import { SAVED_TECHNIQUE } from '../new/actions';

const editTechnique = (state = {}, action) => {
    switch (action.type) {
        case SAVED_TECHNIQUE:
            return {
                ...state,
                wasSaved: true
            };
        default:
            return state;
    }
};

export default editTechnique;
