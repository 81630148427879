import React, { Component } from 'react';
import PropTypes from 'prop-types';

class WorkoutTechniqueListItem extends Component {
    static propTypes = {
        workoutTechnique: PropTypes.shape({
            typeId: PropTypes.number.isRequired,
            rankIsRandom: PropTypes.bool.isRequired,
            rankId: PropTypes.number,
            techniqueIsRandom: PropTypes.bool,
            techniqueId: PropTypes.number
        }),
        techniqueTypes: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })),
        ranks: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })),
        techniques: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })),
        onTechniqueRemoved: PropTypes.func.isRequired,
        onTechniqueReordered: PropTypes.func.isRequired
    }
    renderText() {
        const { workoutTechnique, techniqueTypes, ranks, techniques } = this.props;

        const techniqueType = techniqueTypes.find(t => t.id === workoutTechnique.typeId);
        if (workoutTechnique.rankIsRandom) {
            return `Random ${techniqueType.name}`;
        }

        const rank = ranks.find(r => r.id === workoutTechnique.rankId);
        if (workoutTechnique.techniqueIsRandom) {
            return `Random ${rank.name} ${techniqueType.name}`;
        }

        const technique = techniques.find(t => t.id === workoutTechnique.techniqueId);
        return technique.name;
    }
    render() {
        const { onTechniqueRemoved, onTechniqueReordered } = this.props;

        return (
            <div className="row workout-technique">
                <div className="workout-technique-reorder col-xs-3 col-md-2 col-lg-1">
                    <div className="btn-group">
                        <button className="btn btn-sm btn-default" onClick={() => onTechniqueReordered(true)}>
                            <span className="fa fa-arrow-up"></span>
                        </button>
                        <button className="btn btn-sm btn-default" onClick={() => onTechniqueReordered(false)}>
                            <span className="fa fa-arrow-down"></span>
                        </button>
                    </div>
                </div>
                <div className="workout-technique-name col-xs-8 col-md-9 col-md-10">
                    {this.renderText()}
                </div>
                <div className="workout-technique-remove col-xs-1">
                    <button className="btn btn-sm btn-danger" onClick={() => onTechniqueRemoved()}>
                        <span className="fa fa-trash"></span>
                    </button>
                </div>
            </div>
        );
    }
}

export default WorkoutTechniqueListItem;
