import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div className="jumbotron">
            <h1>Kung Fu Trainer</h1>
            <p>
                Take some notes for different ranks and export them so you can make yourself a nice, hard copy notebook.
The technique notes allow for (and it is recommended to use) <a href="http://daringfireball.net/projects/markdown/syntax" target="_blank">Markdown syntax</a> to format the notes.
	        </p>
            <p><Link className="btn btn-primary" to="/technique/new">New Technique</Link></p>
        </div>
    );
};

export default Home;
