import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';


import WorkoutTechniqueListItem from './WorkoutTechniqueListItem';
import TechniquePicker from '../../technique/picker/component';

import './newWorkout.css';

class NewWorkout extends Component {
    static propTypes = {
        ranks: PropTypes.array.isRequired,
        techniqueTypes: PropTypes.array.isRequired,
        defenses: PropTypes.array.isRequired,
        newWorkout: PropTypes.shape({
            allTechniques: PropTypes.array.isRequired,
            techniques: PropTypes.array.isRequired
        }).isRequired,
        loadTechniques: PropTypes.func.isRequired,
        onTechniqueSelected: PropTypes.func.isRequired,
        reorderTechniques: PropTypes.func.isRequired,
        onTechniqueRemoved: PropTypes.func.isRequired,
        onTechniqueReordered: PropTypes.func.isRequired,
        saveWorkout: PropTypes.func.isRequired,
        reloadWorkouts: PropTypes.func.isRequired
    }
    componentWillMount() {
        this.props.loadTechniques();
    }
    componentWillReceiveProps(nextProps) {
        const {newWorkout} = nextProps;
        if (newWorkout.workoutId) {
            this.props.reloadWorkouts();
            const history = useHistory();
            history.push(`/workout/${newWorkout.workoutId}`);
        }
    }
    reordered(list) {
        this.props.reorderTechniques(list);
    }
    saveWorkout(workout) {
        workout.techniques = this.props.newWorkout.techniques;
        this.props.saveWorkout(workout);
    }
    render() {
        const {ranks, techniqueTypes, defenses, newWorkout, onTechniqueSelected, onTechniqueRemoved, onTechniqueReordered, handleSubmit} = this.props;

        const initialPickerValues = {
            rankIsRandom: true,
            techniqueIsRandom: true
        };

        return (
            <div>
                <div className="row new-workout-properties">
                    <div className="col-md-6">
                        <div className="form-group">
                            <Field id="name" component="input" className="form-control input-lg" name="name" type="text" placeholder="Name" />
                        </div>
                    </div>
                </div>
                <TechniquePicker ranks={ranks}
                    techniqueTypes={techniqueTypes}
                    defenses={defenses}
                    techniques={newWorkout.allTechniques}
                    onTechniqueSelected={onTechniqueSelected}
                    initialValues={initialPickerValues} />
                <div className="row workout-techniques">
                    <div className="col-xs-12">
                        {newWorkout.techniques.map((technique, index) =>
                            <WorkoutTechniqueListItem
                                workoutTechnique={technique}
                                ranks={ranks}
                                techniqueTypes={techniqueTypes}
                                techniques={newWorkout.allTechniques}
                                key={index}
                                onTechniqueRemoved={() => onTechniqueRemoved(index)}
                                onTechniqueReordered={movedUp => onTechniqueReordered(index, movedUp)} />
                        )}
                    </div>
                </div>
                <div className="row new-workout-submit">
                    <div className="col-xs-12">
                        <button type="submit" className="btn btn-primary"
                            onClick={handleSubmit(workout => this.saveWorkout(workout))}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default reduxForm({
    form: 'newWorkout'
})(NewWorkout);
